body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h2 {
  font-size: 1.5em !important;
}

#wpadminbar {
  display: none;
}